<template>
  <div id="signin">
    <header>
      <div class="container">
        <a class="logo">
          <img src="@/assets/demo/logo_w.png" />
        </a>
      </div>
    </header>

    <section class="login">
      <div class="container">
        <div class="form">
          <h1>欢迎登录</h1>
          <div class="form-group account" :class="{ err: err.username }">
            <small v-if="err.username">{{ err.username }}</small>
            <input
              type="text"
              class="form-control"
              name="username"
              placeholder="请输入用户名"
              v-model="username"
              @focus="focus"
              @keyup.enter.native="login"
            />
          </div>
          <div class="form-group password" :class="{ err: err.password }">
            <small v-if="err.password">{{ err.password }}</small>
            <input
              type="password"
              class="form-control"
              name="password"
              placeholder="请输入密码"
              v-model="password"
              @focus="focus"
              @keyup.enter.native="login"
            />
          </div>
          <button
            class="btn btn-primary btn-block"
            @click="login"
            :disabled="send"
          >
            登录{{ send ? "中" : "" }}
          </button>
          <div class="row">
            <div class="left">
              <router-link to="/signup">注册账号</router-link>
            </div>
            <div class="right">
              <router-link to="/forgot">忘记密码</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <tmpl_footer style="margin-top: 0"></tmpl_footer>
  </div>
</template>

<script>
import tmpl_footer from "../../components/common/footer.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  components: {
    tmpl_footer,
  },

  data() {
    return {
      username: "",
      password: "",
      to: "",

      err: {
        username: "",
        password: "",
      },

      send: 0,
    };
  },

  created() {
    if (sessionStorage.getItem("token")) {
      sessionStorage.removeItem("token");
    }

    if (this.$route.query.to) {
      this.to = decodeURI(this.$route.query.to);
    }
  },

  methods: {
    login() {
      const page = this;
      const params = {
        userName: this.username,
        password: this.password,
      };

      if (!params.userName) {
        return (page.err.username = "用户名不能为空");
      }

      if (!params.password) {
        return (page.err.password = "密码不能为空");
      }

      if (page.send) return;

      page.send = true;

      return api
        .post(apis.common.signin, params, { error: false })
        .then((res) => {
          page.send = false;
          store.dispatch("setUserData", {
            token: res,
            customerName: this.username,
          });
          localStorage.setItem("username", this.username);
          if (page.to) page.$router.push({ path: page.to, query: {} });
          else page.$router.push({ path: "/home", query: {} });
        })
        .catch((err) => {
          console.log(err);
          page.send = false;
          if (err.data && err.data.data) {
            if (err.data.code === 4000) page.err.username = err.data.data;
            else store.dispatch("toast", err.data.data);
          } else {
            return store.dispatch("toast", err.data.message);
          }
        });
    },

    focus(e) {
      const target = e.currentTarget.name;
      this.err[target] = "";
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../assets/less/form.less");
@import url("./in.less");
</style>
